import Enumeration from '@/library/enumerations/Enumeration';

/**
 * https://github.com/owowagency/monoa-api/blob/develop/app/Library/Enumerations/AwsJobStatus.php
 */
export default class AwsJobStatus extends Enumeration {
    static SUBMITTED = 0;
    static PROGRESSING = 1;
    static COMPLETE = 2;
    static ERROR = 3;

    static get enumeration() {
        return {
            SUBMITTED: this.SUBMITTED,
            PROGRESSING: this.PROGRESSING,
            COMPLETE: this.COMPLETE,
            ERROR: this.ERROR,
        };
    };
}
