<template>
    <b-form
        class="card-stack"
        @submit="$emit('submit', $event)"
    >
        <input
            type="submit"
            class="d-none"
        >

        <b-card :title="$t('posts.mediaDetails')">
            <b-form-group>
                <b-row align-v="center">
                    <b-col cols="auto">
                        <b-form-group
                            class="mb-0"
                            :invalid-feedback="post.errors.thumbnail"
                            :state="!post.errors.thumbnail"
                        >
                            <ow-image-select
                                v-model="post.thumbnail"
                                value-key="200"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col class="small">
                        {{ $t('posts.model.thumbnail.helpText') }}
                    </b-col>
                </b-row>
            </b-form-group>

            <b-form-group
                v-if="!post.id"
                :label="post.getTranslation('type')"
                :invalid-feedback="post.errors.type"
                :state="!post.errors.type"
            >
                <input-dropdown
                    v-model="post.type"
                    class="w-100"
                    :items="types"
                    variant="light"
                />
            </b-form-group>

            <multi-language-tabs class="mb-4">
                <template #default="{locale}">
                    <input-file-media
                        v-if="post.type === PostType.VIDEO"
                        :ref="`inputFileMedia-${locale}`"
                        v-model="post.video[locale]"
                    />

                    <b-form-group
                        v-else
                        :label="post.getTranslation('files')"
                        :invalid-feedback="filesError"
                        :state="!filesError"
                    >
                        <input-file
                            v-model="post.files[locale]"
                            multiple
                        />
                    </b-form-group>

                    <b-form-group
                        :label="post.getTranslation('title')"
                        :invalid-feedback="post.getErrors('title', locale)"
                        :state="!post.getErrors('title', locale)"
                    >
                        <b-input
                            v-model="post.title[locale]"
                            :placeholder="post.getTranslation('title')"
                        />
                    </b-form-group>

                    <b-form-group
                        :label="post.getTranslation('body')"
                        :invalid-feedback="post.getErrors('body', locale)"
                        :state="!post.getErrors('body', locale)"
                    >
                        <b-textarea
                            v-model="post.body[locale]"
                            rows="4"
                            :placeholder="post.getTranslation('body')"
                        />
                    </b-form-group>
                </template>
            </multi-language-tabs>

            <b-form-group
                :label="post.getTranslation('privacy')"
                :invalid-feedback="post.errors.privacy"
                :state="!post.errors.privacy"
            >
                <radio-group-post-privacy
                    v-model="post.privacy"
                />
            </b-form-group>
        </b-card>
    </b-form>
</template>

<script>
import InputDropdown from '@/components/common/InputDropdown';
import InputFile from '@/components/common/form/InputFile';
import InputFileMedia from '@/components/common/form/InputFileMedia';
import MultiLanguageTabs from '@/components/common/MultiLanguageTabs';
import OwImageSelect from '@/components/common/form/OwImageSelect';
import {Post} from '@/models/Post';
import PostType from '@/library/enumerations/PostType';
import RadioGroupPostPrivacy from '@/components/common/form/RadioGroupPostPrivacy';

export default {
    name: 'PostForm',
    components: {
        InputDropdown,
        InputFile,
        InputFileMedia,
        MultiLanguageTabs,
        OwImageSelect,
        RadioGroupPostPrivacy,
    },
    props: {
        post: {
            type: Post,
            required: true,
        },
    },
    data() {
        return {
            PostType,
            types: PostType.toDropdownItems([
                PostType.VIDEO, PostType.PDF, PostType.IMAGE,
            ]),
        };
    },
    computed: {
        filesError() {
            const key = Object.keys(this.post.errors)
                .find(k => k.indexOf('files') === 0);

            return this.post.errors[key];
        },
    },
    methods: {
        uploadVideo(credential, locale) {
            return this.$refs[`inputFileMedia-${locale}`].uploadMedia(credential);
        },
    },
};
</script>
