<template>
    <div class="video-container">
        <video
            ref="video"
            controls
        />
    </div>
</template>

<script>
import Hls from 'hls.js';

export default {
    name: 'HlsVideoPlayer',
    props: {
        src: {
            type: String,
            required: true,
        },
    },
    mounted() {
        let hls = new Hls();

        hls.loadSource(this.src);

        hls.attachMedia(this.$refs.video);
    },
};
</script>

<style lang="scss" scoped>
.video-container {
    position: relative;
    width: 100%;
    padding-top: percentage(9/16);

    > video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
    }
}
</style>
