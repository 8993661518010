<template>
    <div>
        <!-- Show video player if the video is HLS. -->
        <hls-video-player
            v-if="hlsSrc"
            :src="hlsSrc"
            class="w-100"
        />

        <!-- Else, show video file input. -->
        <vue-file-agent
            v-else
            :value="value"
            :disabled="hlsSrc === null"
            :accept="'video/*'"
            :average-color="false"
            :compact="true"
            :multiple="false"
            :meta="false"
            @input="onInput($event)"
        >
            <template #file-preview-new>
                <div
                    key="file-preview-new"
                    class="file-preview-new"
                >
                    <div class="text-center">
                        <btn-square variant="dark">
                            <fa icon="plus" />
                        </btn-square>

                        <div class="text-muted mt-2">
                            {{ $t('posts.actions.selectMediaFile') }}
                        </div>
                    </div>
                </div>
            </template>
        </vue-file-agent>

        <b-progress
            v-if="uploading"
            :value="bytesUploaded"
            :max="value.size"
            class="mt-3"
        />

        <!-- Show helpful error message. -->
        <!-- eslint-disable vue/no-v-html -->
        <div
            v-if="errorMessage"
            class="small text-center text-danger mt-3"
            v-html="errorMessage"
        />
        <!-- eslint-enable vue/no-v-html -->

        <!-- File Name & Delete Button -->
        <div class="d-flex align-items-center mt-3">
            <div v-if="_.get(value, 'name')">
                {{ value.name() }}
            </div>

            <b-btn
                v-if="value"
                variant="light"
                class="text-danger ml-auto"
                @click="$emit('input', null)"
            >
                <fa :icon="['fac', 'trash']" />
            </b-btn>
        </div>
    </div>
</template>

<script>
import AWS from 'aws-sdk';
import {detainFeedback} from '@/library/helpers';
import {UploadCredential} from '@/models/UploadCredential';
import PostPrivacy from '@/library/enumerations/PostPrivacy';
import AwsJobStatus from '@/library/enumerations/AwsJobStatus';
import HlsVideoPlayer from '@/components/common/HlsVideoPlayer';

/**
 * An input file component that accepts a video to be uploaded.
 */
export default {
    name: 'InputFileMedia',
    components: {HlsVideoPlayer},
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            bytesUploaded: 0,
            uploading: false,
            errorMessage: '',
        };
    },
    computed: {
        /**
         * The 'playlist' key is an indicator of the media object:
         * - string -> HLS video is stored in S3 and ready to be played.
         * - null -> HLS video is being transcoded in S3.
         * - undefined -> Media object is not uploaded yet, show input file media.
         *
         * @return {String|null|undefined}
         */
        hlsSrc() {
            return this._.get(this.value, 'playlist');
        },
    },
    watch: {
        /**
         * Figure out the video's duration.
         */
        'value.file'(val) {
            if (!(val instanceof File)) return;

            // Create <video> element to know the video's duration.
            let video = document.createElement('video');

            video.src = URL.createObjectURL(val);

            video.preload = 'metadata';

            video.onloadedmetadata = function() {
                const duration = video.duration > 0 ? video.duration : 0;

                this.$set(this.value, 'duration', duration);
            }.bind(this);
        },
        /**
         * Determine error message based on the value.
         */
        value: {
            deep: true,
            immediate: true,
            handler: function(value) {
                let message = '';

                // Show error message if hls video is there but the processing is not yet completed ...
                if (
                    !!this.hlsSrc
                    && this._.get(value, 'awsMediaconvertJobStatus') !== AwsJobStatus.COMPLETE
                ) {
                    message = this.$t('posts.messages.mediaProcessing') + '<br>'
                        + this.$t('common.errors.reloadPage');

                // ... or if media already has an id, but is not uploaded to S3.
                } else if (this._.get(value, 'id')) {
                    message = this.$t('posts.messages.mediaPreviousUploadFailed');
                }

                this.errorMessage = message;
            },
        },
    },
    methods: {
        onInput(fileRecord) {
            // Fix a bug in `vue-file-agent` where the url can be a function.
            // This made the video to be unplayable, thus we convert it to `null`
            // instead. Also we actually don't need the url but only the file.
            const value = typeof this._.get(fileRecord, 'url') === 'function'
                ? {...fileRecord, url: null}
                : fileRecord;

            this.$emit('input', value);
        },
        /**
         * Upload media to AWS S3 bucket.
         */
        uploadMedia(credential) {
            if (!(credential instanceof UploadCredential)) {
                throw new Error('Upload credential is invalid.');
            }

            AWS.config.update({
                region: credential.region,
                accessKeyId: credential.sessionCredentials.accessKeyId,
                secretAccessKey: credential.sessionCredentials.secretAccessKey,
                sessionToken: credential.sessionCredentials.sessionToken,
            });

            const bucket = new AWS.S3({
                params: {
                    Bucket: credential.bucket,
                },
            });

            const data = {
                Key: credential.key,
                ContentType: this.value.type,
                Body: this.value.file,
            };

            const onProgress = function(progress) {
                this.bytesUploaded = progress.loaded;
            }.bind(this);

            const onUploaded = async function(err) {
                const message = this.$t(`posts.messages.mediaUpload${err ? 'Failed' : 'Success'}`);

                if (err) this.errorMessage = message;

                await this.$bvModal.msgBoxOk(' ', {
                    title: message,
                    headerClass: 'text-center',
                    bodyClass: 'd-none',
                    okTitle: this.$t('buttons.ok'),
                    size: 'sm',
                    centered: true,
                });

                detainFeedback(() => {
                    this.uploading = false;
                });
            }.bind(this);

            this.uploading = true;

            return new Promise(function(resolve, reject) {
                bucket
                    .upload(data, async(err, data) => {
                        await onUploaded(err);

                        err ? reject(err) : resolve(data);
                    })
                    .on('httpUploadProgress', onProgress);
            });
        },
    },
};
</script>
