import Model from './vue-mc/Model';

/**
 * Credentials to upload media to AWS S3.
 */
export class UploadCredential extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            sessionCredentials: null,
            key: '',
            mimeType: '',
            bucket: '',
            region: '',
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            saveUnchanged: true,
        };
    }
};