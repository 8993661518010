<template>
    <b-form-file
        v-bind="$attrs"
        :placeholder="placeholder"
        v-on="$listeners"
        @input="onInput"
    />
</template>

<script>
export default {
    name: 'InputFile',
    props: {
        value: {
            type: [Object, Array],
            default: undefined,
        },
    },
    computed: {
        isMultiple() {
            return this.$attrs.hasOwnProperty('multiple');
        },
        placeholder() {
            if (!this.value || !this.value.length) {
                return this.isMultiple
                    ? this.$t('inputs.files.placeholderMultiple')
                    : this.$t('inputs.files.placeholder');
            }

            let value = this.value;

            if (!Array.isArray(value)) {
                value = [value];
            }

            return value.map(v => {
                return value instanceof Object
                    ? v.fileName
                    : '';
            }).join(', ');
        },
    },
    methods: {
        async onInput(input) {
            const files = this.isMultiple ? input : [input];

            const value = await Promise.all(
                files.map(f => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader;

                        reader.onload = (e) => {
                            resolve(e.target.result);
                        };

                        reader.onerror = reject;

                        reader.readAsDataURL(f);
                    });
                }),
            );

            this.$emit('input', value);
        },
    },
};
</script>
